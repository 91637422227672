import React from 'react';
import {createRoot} from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Asset from "./Assets/Asset";
import Group from "./Groups/Group";

import './style.css';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="asset" element={<Asset />} />
      <Route path="group/:groupId/:accessorId" element={<Group />} />
    </Routes>
  </BrowserRouter>
);
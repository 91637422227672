import React, {useState} from 'react';
import dayjs from 'dayjs';
import {formatBytes} from "../Utilities/format-bytes";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import getAxiosClient from "../Utilities/get-axios-client";
import Loader from "../Loader/Loader";

const Asset = ({ asset, accessorId, brandOptions }) => (
    <div className="d-flex justify-content-between top-buffer25 bg-white shadow-sm padding20">
        <div className="text-left">
            <div className="font600">{asset.file.name}</div>
            <small className="pad-top5">{formatBytes(asset.file.size)}</small>
        </div>
        <div className="left-buffer20">
            <a
                href={`${process.env.REACT_APP_API_BASE_URL}/download/${asset.groupAssetId}/${accessorId}`}
                download
                className={`btn btn btn-primary ${accessorId === 'preview' && 'disabled'}`}
                style={{
                    backgroundColor: brandOptions.button.background,
                    borderColor: brandOptions.button.background,
                    color: brandOptions.button.color,
                }}
            >
                    Download
            </a>
        </div>
    </div>
);

const Group = () => {
    const [brandOptions, setBrandOptions] = useState({
        logoUrl: '',
        button: {
            background: '',
            color: ''
        },
        page: {
            background: '',
            color: ''
        }
    });
    let { groupId, accessorId } = useParams();
    const [group, setGroup] = useState(null);
    const [error, setError] = useState("");
    useEffect(() => {
        async function getPage() {
            try {
                setError("");
                const response = await getAxiosClient().get(`groups/${groupId}/${accessorId}`);
                const { groupInfo, branding = {} } = response.data;
                setGroup(groupInfo);
                setBrandOptions({
                    logoUrl: branding?.logo?.url ?? '',
                    button: {
                        background: branding?.settings?.buttonColour ?? '',
                        color: branding?.settings?.buttonTextColour ?? '',
                    },
                    page: {
                        background: branding?.settings?.pageColour ?? '',
                        color: branding?.settings?.pageTextColour ?? ''
                    }
                });
            } catch (e) {
                console.log({ e });
                const error = (+e.response.data.code === 403)
                    ? e.response.data.message
                    : 'Unable to download file';
                setError(error);
            }
        }
        getPage();
    }, []);
    if (error !== "") {
        return <div className="pad-top40 text-center">{error}</div>
    }
    if (group === null) {
        return <div className="pad-top40 text-center"><Loader isLoading={true} size={50} /></div>
    }
    return (
        <div
            className="pad-top40 min-height-100vh"
            style={{
                backgroundColor: brandOptions.page.background,
                color: brandOptions.page.color,
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1 text-center">
                        {
                            brandOptions.logoUrl !== '' && (
                                <div className="pad-bottom50"><img alt="logo" width="150" src={brandOptions.logoUrl} /></div>
                            )
                        }
                        <div>
                            <h3 className="font600" style={{ color: brandOptions.page.color }}>Online Attachments</h3>
                            {+group.options.expiryTimestamp > 0
                                ? <p>Expiry date: {dayjs.unix(group.options.expiryTimestamp).format('DD MMMM YYYY')}</p>
                                : ''
                            }
                        </div>
                        <div className="pad-bottom30">
                            {group.assets.map((asset) => <Asset
                                key={asset.uuid}
                                asset={asset}
                                accessorId={accessorId}
                                brandOptions={brandOptions}
                            />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Group;
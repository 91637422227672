import axios from 'axios';
import config from '../config.json';

const getAxiosClient = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: config.axiosDefaults.axiosTimeout,
  });
};

export default getAxiosClient;
